<template>
  <div id="nav" class="sticky">
    <router-link to="/">Home</router-link> |
    <router-link to="/builder">Builder</router-link> |
    <router-link to="/player">Player</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <div id="content">
    <router-view/>
  </div>
  <footer id="footer" class="sticky">
    This is my footer
  </footer>

</template>

<style lang="scss">

html{
  min-height:100%;
}

body {
  --background-color: lightgray;
  --background-panel-color: white;
  --primary-color: #2c3e50;
  --secondary-color: #42b983;
  --font-family: Avenir, Helvetica, Arial, sans-serif;

  --header-height: 38px;
  --footer-height: 38px;

  --padding: 10px;

  margin:0;
  position: fixed;
  height: 100%;
  width:100%;
}

#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-color);
  background-color: var(--background-color);

  position:relative;
  height:100%;
  width:100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

#nav {
  top:0;

  a {
    font-weight: bold;
    color: var(--primary-color);

    &.router-link-exact-active {
      color: var(--secondary-color);
    }
  }
}

#content {
  flex-grow: 1;
  position:relative;

  flex-wrap: wrap;
  max-height: calc(100% - var(--header-height) - var(--footer-height));
  //padding: 10px;
  background-color: var(--background-panel-color);
}

#footer {
  bottom: 0;
}

.sticky {
  position:relative;
  padding: var(--padding);
  text-align: center;
  background-color: var(--background-color);
  z-index:1;
}

</style>
